import { createTheme } from '@material-ui/core/styles';

const arionTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: 18,
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: 'rgba(95, 97, 112, 1)',
      main: 'rgba(55, 58, 76, 1)',
      dark: 'rgba(95, 97, 112, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(247, 247, 248, 1)',
      main: 'rgba(247, 247, 248, 1)',
      dark: 'rgba(229, 229, 229, 1)',
      contrastText: 'rgba(55, 58, 76, 1)',
    },
    error: {
      light: '#e57373',
      main: 'rgba(230, 35, 36, 1)',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(55, 58, 76, 1)',
      secondary: 'rgba(95, 97, 112, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

export default arionTheme;
