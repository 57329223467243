import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import * as serviceWorker from './serviceWorker';

import App from './App';

import arionTheme from './arionTheme';
import { Provider } from 'react-redux';
import getStore from 'core/configureStore';
import './locales/i18n';

const store = getStore();
ReactDOM.render(
    <ThemeProvider theme={arionTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
