import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/auth';

import { environment as env } from 'environments';
import { axiosInstance } from 'utils/API';
import LocalStorageService from 'services/LocalStorageService';
import moment from 'moment';
import { isNotActualCache } from 'utils/helpers.js';
import { DATE_TIME_FORMAT } from 'utils/DateFormats';
import { Platform } from "../utils/platform";
const LAST_FIREBASE_AUTH_DATE = 'lastFirebaseAuthDate';
const FIREBASE_CUSTOM_TOKEN = 'firebaseCustomToken';

firebase.initializeApp(env.firebase);

const firestoreState = firebase.firestore();

firestoreState.enablePersistence().catch(function(err) {
  if (err.code === 'failed-precondition') {
    console.log('persistance failed');
  } else if (err.code === 'unimplemented') {
    console.log('persistance not available');
  }
});

firebase
  .auth()
  .setPersistence(process.env.NODE_ENV === 'test'
  ? firebase.auth.Auth.Persistence.NONE
  :firebase.auth.Auth.Persistence.SESSION)
  .catch(error => {
    console.log('setPersistence error', error);
  });

export const firebaseSignOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => console.log('firebase success logout'))
    .catch(err => console.log('firebase logout error', err));
};
const signInWithCustomToken = customToken => {
  if (customToken) {
    firebase
      .auth()
      .signInWithCustomToken(customToken)
      .then(res => {
        localStorage.setItem(
          LAST_FIREBASE_AUTH_DATE,
          moment().format(DATE_TIME_FORMAT)
        );
        console.log('firebase success authenticate');
      })
      .catch(err => {
        console.log('firebase.auth err', err);
      });
  }
};

export const firebaseAuthenticate = () => {
  axiosInstance
    .get('/firebase-token')
    .then(res => {
      const { customToken } = res && res.data;
      if (customToken) {
        localStorage.setItem(
          FIREBASE_CUSTOM_TOKEN,
          JSON.stringify(customToken)
        );
        signInWithCustomToken(customToken);
      }
    })
    .catch(err => {
      const customToken =
        JSON.parse(localStorage.getItem(FIREBASE_CUSTOM_TOKEN)) || null;
      if (customToken) {
        signInWithCustomToken(customToken);
      } else {
        console.log('firebase-token error', err);
      }
    });
};

export const deleteWebPushSubscription = (currentToken) => {
  if (!currentToken) {
    return Promise.resolve();
  }

  return axiosInstance
    .delete('/messages/subscriptions', {data: {token: currentToken}});
}

export const initMessaging = () => {
  const platform = Platform.getPlatform();
  const currentToken = LocalStorageService.getWebPushSubscription();

  if (!platform.isMessagingSupported) {
    return;
  }

  if (!firebase.messaging.isSupported()) {
    return;
  }

  firebase.messaging()
    .getToken()
    .then(token => {
      if (token && token !== currentToken) {
        deleteWebPushSubscription(currentToken)
          .catch(e => console.log(e));

        axiosInstance
          .post('/messages/subscriptions', { token: token })
          .then(res => LocalStorageService.setWebPushSubscription(res.data?.token))
          .catch(e => console.log(e));
      } else if(!token) {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
      }
    })
    .catch(err => {
      console.error('An error occurred while retrieving token.', err);
    });
}

export const checkAndReFetchFirebaseAuth = () => {
  const lastCacheDate = localStorage.getItem(LAST_FIREBASE_AUTH_DATE) || null;
  if (isNotActualCache(lastCacheDate, 59)) {
    firebaseSignOut();
    firebaseAuthenticate();
  }
};

export const firestore = firestoreState;
export default firebase;
