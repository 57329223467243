import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './PreloadProgressBar.scss';

const PreloadProgressBar = ({ absolute = false }) => {
  return (
    <div
      className={`preload-component ${
        absolute ? 'preload-component__absolute' : ''
      }`}
    >
      <CircularProgress
        role="preloadprogressbar"
        id="preloadprogressbar"
        aria-label="Circular PreloadProgressBar"
        aria-labelledby="labeldiv"
      />
    </div>
  );
};

export default PreloadProgressBar;
